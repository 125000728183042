.imageContainer {
    height: 124px;
    width: 124px;
    min-width: 124px;
    border-radius: 50%;
    overflow: hidden;
    margin-bottom: 20px;
}

@media only screen and (max-width: 768px) {
    .imageContainer {
        width: 100px;
        height: 100px;
        min-width: 100px;
    }
}

.imageContainer > img {
    width: 100%;
    height: 100%;
}

#pageSummary {
    margin-top: 16px;
    display: flex;
    flex-direction: row;
}

#pageSummary > *:not(:last-child) {
    margin-right: 16px;
}