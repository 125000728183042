* {
    font-family: 'Roboto', sans-serif !important;
}

html, body {
    font-size: 100%;
}

.dbSection {
    padding-top: 60px;
    padding-bottom: 60px;
}

.dbSection:nth-of-type(odd) {
    background-color: #333;
    color: #fff;

}

.dbLinkOrange {
    color: #FF5722;
}

.dbLinkOrange:hover {
    color: #d65422;
}