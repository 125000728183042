.imageContainer {
  width: 100%;
  margin-top: 32px;
  margin-bottom: 32px;
  display: flex;
  flex-direction: row;
  justify-content: center;

  img {
    max-width: 100%;
    max-height: 500px;
    width: auto;
    box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .075) !important;
    border-radius: .25rem !important;
  }
}

.project {
  margin-top: 36px;
  padding: 20px;
}

.project:not(:last-child) {
  border-bottom: 1px solid #eee;
}
