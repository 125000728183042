#container {
    text-align: center;
    padding-bottom: 80px;
}

.contactContainer {
    display: flex;
    justify-content: center;
    flex-direction: row;
}
